import React, { forwardRef, Ref, SVGProps } from "react";

const BrandIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (

        <svg ref={ref} {...props}  viewBox="0 0 160.627 55.608">
            <g id="Layer_2" data-name="Layer 2" transform="translate(-13.523)">
                <g id="Layer_1" data-name="Layer 1">
                    <path id="Path_2" data-name="Path 2" d="M152.528,853v16.256H149.38V853Z" transform="translate(-79.683 -833.325)" fill="currentColor" />
                    <path id="Path_3" data-name="Path 3" d="M245.38,869.256V853h5.534a6.7,6.7,0,0,1,3.919,1,3.228,3.228,0,0,1,1.415,2.787,3.506,3.506,0,0,1-.829,2.3,4.544,4.544,0,0,1-2.23,1.429v.034a4.436,4.436,0,0,1,2.738,1.281,3.655,3.655,0,0,1,1.016,2.628,4.3,4.3,0,0,1-1.568,3.488,6.367,6.367,0,0,1-4.2,1.3Zm3.148-13.837v4.263h1.711a2.993,2.993,0,0,0,1.989-.6,2.088,2.088,0,0,0,.717-1.7q0-1.966-2.806-1.966Zm0,6.691v4.727h2.165a3.309,3.309,0,0,0,2.165-.64,2.171,2.171,0,0,0,.773-1.778q0-2.31-3.048-2.309Z" transform="translate(-162.099 -833.325)" fill="currentColor" />
                    <path id="Path_4" data-name="Path 4" d="M406.653,869.256h-3.643l-2.585-4.294a6.2,6.2,0,0,0-1.336-1.712,1.962,1.962,0,0,0-1.193-.411h-1.149v6.417H393.6V853h5.518q5.632,0,5.631,4.386a4.367,4.367,0,0,1-.944,2.8,5.085,5.085,0,0,1-2.688,1.684v.044a5.7,5.7,0,0,1,2.462,2.507Zm-9.9-13.8v4.925h1.878a2.813,2.813,0,0,0,2.049-.746,2.485,2.485,0,0,0,.764-1.86q0-2.321-2.762-2.319Z" transform="translate(-289.347 -833.325)" fill="currentColor" />
                    <path id="Path_5" data-name="Path 5" d="M553.883,869.256H544.47V853h9.044v2.618h-5.9v4.142H553.1v2.606h-5.479v4.286h6.267Z" transform="translate(-418.87 -833.325)" fill="currentColor" />
                    <path id="Path_6" data-name="Path 6" d="M686.328,869.256H676.92V853h9.044v2.618h-5.9v4.142h5.479v2.606h-5.479v4.286h6.261Z" transform="translate(-532.579 -833.325)" fill="currentColor" />
                    <path id="Path_7" data-name="Path 7" d="M812.047,855.618H807.4v13.639H804.24V855.618H799.6V853h12.451Z" transform="translate(-637.901 -833.325)" fill="currentColor" />
                    <path id="Path_8" data-name="Path 8" d="M191.727,27.8,215.155,47,208.1,55.606l-24.27-19.883L182.94,35,174.159,27.8,172.7,26.608V.258h11.131V19.883L208.1,0l7.054,8.611Z" transform="translate(-159.177)" fill="currentColor" />
                    <path id="Path_9" data-name="Path 9" d="M181.885,499.675l-.057-.047-.194-.147L172.7,492.59v11.417a5.615,5.615,0,1,0,9.185-4.332Z" transform="translate(-159.177 -454.018)" fill="#ED9E4E" />
                </g>
            </g>
        </svg>
    );
};

const ForwardRef = forwardRef(BrandIcon);
export default ForwardRef;
