import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslator } from "../../hooks/useTranslator";
import BrandIcon from "../Ui/Icons/homePage/BrandIcon";

export const AppFooter: React.FC = () => {
    const { __T } = useTranslator();
    const router = useRouter();
    const { locale: activeLocale } = router;

    return (
        <>
            <div className="bg-navy-100 px-3 py-9">
                <div className="container mx-auto flex flex-wrap">
                    <div className="w-full md:w-1/2 lg:w-1/3 text-start mb-6 lg:mb-0">
                        <Link href="/">
                            <BrandIcon width={130} color="#fff" />
                        </Link>
                        <p className="text-white text-md mt-3">
                            {__T('The best technical system in (KSA) to control the exchange of motor oils')}<br />
                        </p>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 text-start mb-6 lg:mb-0">
                        <h2 className="text-xl font-extrabold text-white ">
                            {__T('About us')}
                        </h2>
                        <p className="text-white font-light">
                            {__T('A technical system that brings all kinds of vehicle service centers together and makes it easier for Companies and Individuals to control vehicle budgets by converting paperwork into digital operations with accuracy and precision, which is documented with reports and figures to prevent manipulations.')}
                        </p>
                        <p className="text-base text-white"> {__T('Headquarters: 3362 - Hafsa Bint Omar - Al Rawdah District - Riyadh')}</p>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/3 text-start">
                        <h4 className="text-white font-bold text-lg">{__T('Company')}</h4>
                        <ul className="text-white list-disc list-inside">
                            <li className="mb-2">
                                <a href="http://">{__T('Terms')}</a>
                            </li>
                            <li className="mb-2">
                                <Link href={`/${activeLocale}/privacy`}>{__T('Privacy')}</Link>
                            </li>
                            <li>
                                <a href="http://">{__T('Support')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="w-full text-center py-4">
                <p className="text-base text-black"> © {__T('tamait.io - all rights reserved.')}</p>
            </div>
        </>
    );
};
