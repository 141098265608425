import { http } from "../clients";
import { STORAGE_KEYS } from "../constants";
import { localStore } from "../localStore";

export const apiClient: any = (baseURL: string) => {
  
  const currencyIso = "SAR";
  const lang = localStore.get(STORAGE_KEYS.LANG, true) || null;
  const headers: any = {
    lang: lang === "en" ? 0 : 1,
    currency: currencyIso,
  };

  const client = http({
    baseURL,
    headers,
  });
  return client;
};
